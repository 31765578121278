import React from 'react';
import AdUI from '@/components/AdUI';
import { Link as Linkr } from 'react-router-dom';

import Home from '@mui/icons-material/Home';
import {
  Card,
  Link,
  List,
  Avatar,
  ListItem,
  CardHeader,
  Typography,
  Breadcrumbs,
  ListItemButton,
} from '@mui/material';

import db from '../../_data/lgs.json';

export function Component() {
  return (
    <>
      <title>Top Leagues - Soccer Streams</title>
      <meta
        name="description"
        content="You can watch Top Leagues live stream online for free at Reddit Soccer Streams. Watch HD Soccer streams for free on Soccer Streams."
      />
      <meta property="og:title" content="Top Leagues - Soccer Streams" />
      <meta
        property="og:description"
        content="You can watch Top Leagues live stream online for free at Reddit Soccer Streams.Watch HD Soccer streams for free on Soccer Streams."
      />
      <link rel="canonical" href={window.location} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png"
      />
      <meta property="og:url" content={window.location} />
      <meta property="og:site_name" content="Soccer Streams" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="soccerstreams100" />
      <meta name="twitter:label2" content="Est. reading time" />
      <meta name="twitter:data2" content="1 minute" />

      <Card>
        <CardHeader
          avatar={<Home />}
          title={
            <Breadcrumbs
              itemScope
              itemType="https://schema.org/BreadcrumbList"
              aria-label="breadcrumb"
            >
              <Link
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                component={Linkr}
                title="Soccer Streams"
                sx={{ display: 'flex', alignItems: 'center' }}
                underline="hover"
                color="inherit"
                to="/"
              >
                <meta itemProp="item" content="https://new.soccerstreams-100.tv/" />
                <meta itemProp="position" content="1" />
                <meta itemProp="name" content="Soccer Streams" />
                Soccer Streams
              </Link>
              <Typography
                color="text.primary"
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <meta itemProp="item" content={window.location} />
                <meta itemProp="position" content="2" />
                <meta itemProp="name" content="Leagues" />
                Leagues
              </Typography>
            </Breadcrumbs>
          }
        />
        <AdUI format="horizontal" />
        <List dense disablePadding>
          {db.map((d) => (
            <ListItem
              key={d.slg}
              component={Linkr}
              disableGutters
              disablePadding
              dense
              to={`/league/${d.slg.replaceAll('.', '-')}`}
            >
              <ListItemButton sx={{ textDecoration: 'none' }} disableRipple>
                <CardHeader
                  avatar={
                    <Avatar
                      alt={d.n}
                      variant="square"
                      src={`${process.env.CDN}/api/img/football/league/${d.slg}?w=80&h=80`}
                    />
                  }
                  title={d.sn}
                  subheader={d.n}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Card>
    </>
  );
}
