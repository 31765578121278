import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';

import BrokenImageIcon from '@mui/icons-material/BrokenImage';

const errorIcon = <BrokenImageIcon color="secondary" />;

function DefaultImage({ size }) {
  const avatarSize = size || 24;
  const imageUrl = `${process.env.CDN}/api/img/soccer/default-team-logo-500.png?w=${
    avatarSize * 2
  }&h=${avatarSize * 2}`;
  return (
    <Image
      width={avatarSize}
      height={avatarSize}
      src={imageUrl}
      duration={500}
      errorIcon={errorIcon}
    />
  );
}

DefaultImage.propTypes = {
  size: propTypes.number,
};

export default DefaultImage;
