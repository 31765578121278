import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';

const atOptions = {
    'key' : 'fcaf1d657a19c50a3821c7d0e7c1fd77',
    'format' : 'iframe',
    'height' : 60,
    'width' : 468,
    'params' : {}
};


export default function AdsTerra_468x60() {
    const banner = useRef();

    useEffect(() => {
        if (banner.current && !banner.current.firstChild) {
            const conf = document.createElement('script')
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.src = `//www.highperformancedformats.com/${atOptions.key}/invoke.js`
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`

            banner.current.append(conf)
            banner.current.append(script)
        }
    }, [banner])

    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} ref={banner}></Box>
}