import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.CDN}/api`,
  }),
  endpoints: (build) => ({
    getGames: build.query({
      query: () => '/sport/football',
    }),
    gameById: build.query({
      query: (gameId) => `/game/football/${gameId}`,
    }),
    leagueById: build.query({
      query: (leagueId) => `/sport/football/league/${leagueId}`,
    }),
    posts: build.query({
      query: () => `/news/football`,
    }),
    postById: build.query({
      query: (postId) => `/news/football/${postId || 1}`,
    }),
  }),
});

export const {
  useLeagueByIdQuery,
  useGetGamesQuery,
  useGameByIdQuery,
  usePostsQuery,
  usePostByIdQuery,
} = api;
