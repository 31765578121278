import React from 'react';
import Image from 'mui-image';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Grid, List, Paper, CardHeader, IconButton, ListSubheader } from '@mui/material';

import Player from '../Player';
import { IRoster } from '../_types';

const Roster = React.memo(({ frt, rst, t }) => {
  const [v, setV] = React.useState(0);
  const handleV = (newV) => setV((v + newV) % 2);

  const players = React.useMemo(() => new Map(rst.map(({ ath }) => [ath.i, ath])), [rst]);

  return (
    <Grid item xs={12} sm={6} md={6}>
      <List
        sx={{ height: '100%' }}
        component={Paper}
        subheader={
          <ListSubheader
            component={CardHeader}
            avatar={
              <Image
                alt={`${t?.ab} Soccer Streams`}
                src={`${process.env.CDN}/api/img/soccer/${t.i}.png?w=48&h=48`}
                width={32}
                height={32}
              />
            }
            title={t.ab + (v === 0 ? ' Starters' : ' Substitutes')}
            subheader={frt}
            action={
              <>
                <IconButton onClick={() => handleV(1)}>
                  <ChevronLeft />
                </IconButton>
                <IconButton onClick={() => handleV(1)}>
                  <ChevronRight />
                </IconButton>
              </>
            }
          />
        }
      >
        {rst
          ?.filter((row) => (v === 0 ? row.srt : !row.srt))
          .map((row) => (
            <Player getAthlete={(id) => players.get(id)} key={row.jsy} {...row} />
          ))}
      </List>
    </Grid>
  );
});

Roster.prototype = IRoster;
export default Roster;
